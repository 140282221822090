import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";
import { decodeHtml } from "../utils/BlogUtils";

export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: fetchQuery(),
  endpoints: (build) => ({
    getListNews: build.query<Application.ResponseProps<Blog.Blog[]>, void>({
      query: () => ApiRoutes.GetBlogList,
      transformResponse: (res) => {
        if (!res) return res;
        const newItems = res.data.items.map((item) => {
          return {
            ...item,
            content: decodeHtml(item.content),
            previewContent: decodeHtml(item.previewContent),
          };
        });
        return {
          ...res,
          data: newItems,
        };
      },
    }),
  }),
});

export const { useGetListNewsQuery } = blogApi;
