import { AlertColor, Box, BoxProps, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTheme } from "../../theme/Theme";
import { WarningIcon } from "../icons/WarningIcon";

interface Props extends BoxProps {
  text?: string;
  innerText?: string;
  variant?: AlertColor;
  rightElement?: ReactElement;
  elementText?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export function Alert({
  text,
  rightElement,
  elementText,
  variant = "info",
  innerText,
  ...props
}: Props) {
  const theme = useTheme();
  return (
    <Box
      p={theme.typography.pxToRem(16)}
      borderRadius={theme.typography.pxToRem(16)}
      border={`1px solid ${theme.palette.sun.main}`}
      {...props}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" flexGrow={1} flexShrink={1}>
          <WarningIcon />
          {elementText ? (
            <>{elementText}</>
          ) : (
            <Typography
              dangerouslySetInnerHTML={innerText ? { __html: innerText } : undefined}
              variant="text4"
              sx={{ whiteSpace: "pre-line" }}
              color={theme.palette.cow.main}
              ml={theme.typography.pxToRem(12)}
            >
              {text}
            </Typography>
          )}
        </Stack>
        {rightElement}
      </Stack>
    </Box>
  );
}
