import cx from "classnames";
import React, { ReactNode } from "react";

import { LogoIcon } from "../icons/LogoIcon";
import { makeStyles } from "../../theme/Theme";
import { AppLayoutContent } from "./AppLayoutContent";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";

const useStyles = makeStyles((theme) => ({
  root_AppLayoutHeaderContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "inherit",
    marginLeft: 0,
    marginRight: 0,
    marginTop: "10px",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 20,
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      margin: "0 auto",
      marginTop: 20,
      marginBottom: 20,
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      height: 40,
    },
  },
  logoWrapper: {
    marginRight: 116,
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginRight: 40,
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginRight: 116,
    },
  },
}));

interface Props {
  readonly className?: string;
  readonly classNameContent?: string;
  readonly children?: ReactNode;
}

export function AppLayoutHeaderContent({ children, className, classNameContent }: Props) {
  const classes = useStyles();

  return (
    <AppLayoutContent className={cx(classes.root_AppLayoutHeaderContent, className)}>
      <div className={classes.logoWrapper}>
        <NavLink to={AppRoutes.DashboardBriefcaseLink}>
          <LogoIcon />
        </NavLink>
      </div>
      <div className={cx(classNameContent)}>{children}</div>
    </AppLayoutContent>
  );
}
