import Stack from "@mui/material/Stack";
import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";

import { useTheme } from "../../theme/Theme";
import { StackProps } from "@mui/material";

export interface FormControlProps extends StackProps {
  readonly error?: string;
  readonly className?: string;
  readonly children: ReactNode;
  style?: React.CSSProperties;
}

export function FormControl({ error, className, style, children, ...props }: FormControlProps) {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={1} style={style} className={className} {...props}>
      {children}

      {!!error && (
        <Stack height={theme.pxToRem(24)}>
          <Typography color={theme.palette.sparrow.main} variant="text1">
            {error}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
