import React, { ComponentProps, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { IMaskInput } from "react-imask";
import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "../../theme/Theme";
import { limitByLength, validateField } from "../../utils/FieldUtils";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { FormControl } from "../form/FormControl";
import { Stack, Typography } from "@mui/material";
import classNames from "classnames";

interface StylesProps {
  readonly disabled?: boolean;
  readonly inputTextColor?: string;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  rootInput: (props: StylesProps) => ({
    "&.MuiTextField-root": {
      "& > .MuiInputBase-root": {
        opacity: props.disabled ? 0.5 : 1,
        overflow: "hidden",
        backgroundColor: theme.palette.back.main,
        borderRadius: theme.typography.pxToRem(20),
        "&.MuiInputBase-multiline": {
          padding: 0,
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          transition: "border-color 270ms ease-in-out",
          borderRadius: theme.typography.pxToRem(20),
          borderColor: theme.palette.common.transparent,
        },
        "& > .MuiInputBase-input": {
          height: "20px",
          color: (props) => props.inputTextColor || theme.palette.cow.main,
          padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(23)}`,
          fontSize: 16,
          lineHeight: "19px",
          fontWeight: 400,

          borderColor: theme.palette.common.transparent,

          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(17),
          },

          "&:focus + .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),
          },
        },
        "&:hover:not(.Mui-disabled)": {
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),

            [theme.breakpoints.down(Breakpoints.Tablet)]: {
              borderColor: theme.palette.common.transparent,
            },
          },
        },
      },
    },
  }),
  error: {
    color: theme.palette.sparrow.main,
    paddingBottom: "16px",
    paddingTop: "16px",
    opacity: 0,
    transition: "all 0.4s",
  },
  error_visible: {
    opacity: 1,
    transition: "all 0.4s",
  },
  hideWidth: {
    position: "absolute",
    opacity: 0,
  },
  rightComponent: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 24,
  },
  rootWrapper: {
    position: "relative",
  },
}));

export interface TextInputProps extends Omit<TextFieldProps, "value"> {
  readonly value?: string;
  maxlength?: string | number;
  inputCharactersType?: Survey.InputCharactersType;
  readonly inputTextColor?: string;
  mask?: string;
  showShowMask?: boolean;
  styleInput?: React.CSSProperties | undefined;
  rightComponent?: React.ReactNode;
}

export function TextInput({
  className,
  inputTextColor,
  disabled,
  maxlength,
  inputCharactersType,
  mask,
  styleInput,
  onKeyDown,
  rightComponent,
  onInput,
  ...props
}: TextInputProps) {
  const classes = useStyles({ disabled, inputTextColor });
  const refInput = useRef<HTMLInputElement | null>(null);
  const idRef = useRef(props?.id ?? "");
  useEffect(() => {
    if (props?.id) return;
    // Генерация уникального ID при монтировании компонента
    idRef.current = uuidv4();
  }, [props?.id]);
  useLayoutEffect(() => {
    if (!refInput.current) return;
    const input = refInput.current;

    if (maxlength) limitByLength(input, maxlength.toString());
  }, [maxlength]);

  return (
    <Stack className={classes.rootWrapper}>
      <TextField
        inputRef={refInput}
        autoCorrect="off"
        for={idRef.current}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        className={cx(classes.rootInput, className)}
        disabled={disabled}
        inputMode={inputCharactersType === "only-numbers" ? "numeric" : props.inputProps?.inputMode}
        InputProps={{
          // @ts-ignore
          inputComponent: mask ? InputWithMask : undefined,
          // @ts-ignore
          inputProps: { maskCustom: mask, styleInput, idComponent: idRef.current, ...props },
        }}
        {...props}
        onInput={(e) => {
          if (inputCharactersType && !mask) {
            validateField(e, inputCharactersType);
          }
          if (onInput) {
            onInput(e);
          }
        }}
        onKeyDown={onKeyDown}
      />
      {rightComponent && <div className={classes.rightComponent}>{rightComponent}</div>}
    </Stack>
  );
}
type PropsTextInputWithMask = {
  maskCustom: string;
  inputTextColor: string;
  styleInput?: React.CSSProperties;
  idComponent?: string;
} & ComponentProps<typeof IMaskInput> &
  TextInputProps;

const InputWithMask = React.forwardRef<HTMLInputElement | null, PropsTextInputWithMask>(
  (props, ref) => {
    const {
      inputTextColor,
      mask,
      disabled,
      className,
      showShowMask,
      styleInput,
      idComponent,
      placeholder,
      ...otherProps
    } = props;
    const propsInput: ComponentProps<typeof IMaskInput> = {
      ...(otherProps as any),
      mask: otherProps.maskCustom,
    };

    const classes = useStyles({ disabled, inputTextColor });

    const spanRef = useRef<HTMLElement>(null);

    const [width, setWidth] = useState<number | string>(2);
    const error = useRef<string>("");
    const [visible, setVisible] = useState<boolean>(false);

    const hiddenText = useMemo(() => {
      if (!propsInput.value && placeholder) {
        return placeholder;
      }

      return propsInput.value;
    }, [propsInput.value, placeholder])

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!propsInput?.value) return;
      if (!showShowMask) return;
      if (!propsInput.mask) return;

      const lengthValue = propsInput.value.length;
      const lengthMask = otherProps.maskCustom.length;
      const countMaskSymbol = lengthMask - lengthValue;

      if (lengthMask === lengthValue) {
        setVisible(false);
        return;
      }
      setVisible(true);

      error.current = otherProps.maskCustom.slice(-countMaskSymbol);
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      error.current = "";
      setVisible(false);
    };

    useEffect(() => {
      if (spanRef.current) {
        setWidth(spanRef.current.offsetWidth || "auto");
      }
    }, [propsInput.value]);

    return (
      <FormControl
        style={{
          // @ts-ignore
          flexDirection: "row !important",
        }}
        className={cx(classes.rootWrapperMask)}
      >
        <Stack direction="row">
          <Typography variant={"input1"} ref={spanRef} className={classes.hideWidth}>
            {hiddenText}
          </Typography>
          <IMaskInput
            style={{
              paddingLeft: "23px",
              paddingBottom: "16px",
              paddingTop: "16px",
              paddingRight: "0",
              width,
              ...styleInput,
            }}
            id={idComponent}
            inputRef={ref}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            placeholder={placeholder}
            disabled={disabled}
            {...propsInput}
            className={cx(classes.rootInput, className)}
            onBlur={(e) => {
              otherProps?.onBlur && otherProps.onBlur(e);
              onBlur(e);
            }}
            onFocus={(e) => {
              otherProps?.onFocus && otherProps.onFocus(e);
              onFocus(e);
            }}
          />
          <Typography
            className={classNames(classes.error, {
              [classes.error_visible]: visible,
            })}
          >
            {error.current}
          </Typography>
        </Stack>
      </FormControl>
    );
  },
);
