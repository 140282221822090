export enum ApiRoutes {
  Logout = "api/auth/logout",
  AuthSendCode = "api/auth/send-code",
  AuthCheckCode = "api/auth/check-code",

  // Registration система автоматически подставит - "cold/"
  RegistrationConfirm = "api/confirm/registration/confirmCode/saveUserInfo",
  RegistrationGenerate = "api/confirm/registration/generateCode/saveUserInfo",
  RegistrationConfirmEmail = "api/confirm/registration/confirmEmail",
  RegistrationSavePersonalData = "api/registration/savePersonalInfo",

  Briefcase = "api/portfolio",
  BriefcaseStrategy = "api/strategy",
  BriefcaseStrategyList = "api/strategy/list",
  BriefcaseStrategyChart = "api/strategy/chart",
  BriefcaseChart = "api/portfolio/chart",

  // strategy
  StrategySaveSurvey = "api/strategy/saveSurveyByStrategyBuy",
  StrategyGenerateSmsPromoStrategy = "api/strategy/promo/generate-sms",
  StrategySignSmsPromoStrategy = "api/strategy/promo/sign",
  StrategyPromoList = "api/strategy/promo/list",
  StrategyGetErrorByBuyStrategy = "api/strategy/personalDataError",
  StrategyGetPersonalDataWithError = "api/strategy/getDataWithError",
  StrategySavePersonalDataWithFix = "api/strategy/getDataWithError",
  StrategyGetPersonalData = "api/strategy/personalData",
  StrategyGetAgreement = "api/strategy/getAgreement",
  RiskAgreement = "api/strategy/getRiskAgreement",

  ManageFundsPaymentRequisite = "api/payment/requisite/pdf",
  ManageFundsPaymentSBP = "api/payment/requisite/sbp",
  ManageFundsGetReserveList = "api/reserve/list-v2",
  ManageFundsRequisiteList = "api/requisite/list",
  ManageFundsRequisite = "api/requisite/item",
  ManageFundsRequisiteGenerateCode = "api/requisite/item/generate",

  ManageFundsOrderGenerateCode = "api/order/generate-sms",
  ManageFundsOrderSign = "api/order/sign",
  ManageFundsOrderSignCheck = "api/order/sign/check",
  ManageFundsWithdrawalStrategyToStrategy = "api/withdrawal/strategy/to/strategy",
  CheckCodeBySms = "api/order/checkCodeBySMS",
  ManageFundsWithdrawalStrategyToRequisite = "api/withdrawal/requisite/strategy/generate",
  ManageFundsRecurrentOperationsList = "api/operations/regular/list",
  ManageFundsRecurrentOperationDisable = "api/operations/regular/disable",

  ProfileMe = "api/profile/me",
  ProfileSettings = "api/profile/settings",
  ProfileAvatar = "api/profile/me/avatar",
  ProfileTaxStatus = "api/profile/taxStatus",
  ProfileTaxCheck = "api/profile/taxStatus/check",
  ProfileNdflReference = "api/profile/documents/2ndfl",
  ProfilePhoneChange = "api/profile/phone/change",
  ProfilePhoneSendCode = "api/profile/phone/code/send",
  ProfilePhoneCheckCode = "api/profile/phone/code/check",
  ProfileEmailChange = "api/profile/email/change",
  ProfileEmailSendCode = "api/profile/email/code/send",
  ProfileEmailCheckCode = "api/profile/email/code/check",

  TwoFactorAuthInfo = "api/profile/2fa/info",
  TwoFactorAuthEnable = "api/profile/2fa/enable",
  TwoFactorAuthDisable = "api/profile/2fa/disable",
  TwoFactorAuthSendSmsCode = "api/profile/2fa/sms/send",
  TwoFactorAuthGoogleCode = "api/profile/2fa/code/generate",

  CommonUploadFile = "api/files/upload",
  Agreement = "api/agreement",
  Callback = "api/callback",
  CallbackAvailableTimes = "api/callback/available-times",
  AugustInfo = "api/public/company/info",
  ConversionInfo = "api/agreement/conversion",

  CurrencyList = "api/currency/list",

  // Survey
  SurveyGenerateCode = "api/survey/generate",
  SurveyData = "api/survey/items",
  SurveyPreviousAnswers = "api/survey/previous",

  // Operations journal
  OperationsJournalWithdrawalItems = "api/journal-operation/withdrawal/list",
  OperationsJournalDepositItems = "api/journal-operation/replenishment/list",
  OperationsJournalTransferItems = "api/journal-operation/transfer/list",
  OperationsJournalWithdrawalCancel = "api/journal-operation/withdrawal/:id/cancel",
  OperationsJournalWithdrawalCancelConfirm = "api/journal-operation/withdrawal/:id/cancel-confirm",
  OperationsJournalOtherItems = "api/journal-operation/otherOperation/list",

  // Requisites
  RequisiteList = "api/requisite/list",
  ChangeRequisite = "api/requisite/change",
  ConfirmChangeRequisiteBySms = "api/requisite/confirmBySmsCode",
  ConfirmChangeRequisiteBy2Fa = "api/requisite/confirmByGoogleAuthCode",

  // Bank
  BankList = "api/banks/list",

  // qr-code
  GetQrCodeAuth = "api/auth/get-qrcode",
  CheckQrCodeAuth = "api/auth/check-qrcode-authorized",

  // Документы
  DocumentsList = "api/documents",
  DocumentDetail = "api/documents/:id",
  DocumentSign = "api/documents/:id/sign",
  DocumentSignCheck = "api/documents/:id/sign-check",

  // Заказ справок
  GetListReferenceInformation = "api/documents/getReferencesInformation",
  RequestReferenceInformation = "api/documents/requestReferenceInformation",
  ConfirmReferenceInformation = "api/documents/referenceInformation/sign",

  // Блог
  GetBlogList = "api/site/news",

  // Captcha
  RefreshCaptcha = "api/captcha/refresh",
  SendCaptcha = "api/captcha/click",

  // Buy new strategy
  PreviewInformation = "api/strategy/getPreviewPersonalData",
  StrategyListForSign = "api/strategy/for/sign/list",

  // set checkpoint auth
  SetCheckpointAuth = "api/registration/setCheckpoint",
}
