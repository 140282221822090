import { Breakpoints } from "../dto/ApplicationDTO";

export function createMuiTypographyComponent(): MuiTheme.MuiButton {
  return {
    styleOverrides: {
      root: ({ theme }) => ({
        fontFamily: "SFPro Display, sans-serif",
        "&.MuiTypography-h0": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "32px",
            lineHeight: "32px",
          },
        },
        // TODO: для таблета h1 украли
        "&.MuiTypography-h1": {
          [theme.breakpoints.only(Breakpoints.Mobile)]: {
            fontSize: "24px",
            lineHeight: "28px",
          },
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "24px",
            lineHeight: "28px",
          },
        },
        "&.MuiTypography-h3": {
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
        "&.MuiTypography-button1": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "14px",
            lineHeight: "17px",
          },
        },
        "&.MuiTypography-text1": {
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "12px",
            lineHeight: "16px",
          },
        },
        "&.MuiTypography-text5": {
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "10px",
            lineHeight: "14px",
          },
        },
        "&.MuiTypography-text6": {
          [theme.breakpoints.only(Breakpoints.Mobile)]: {
            fontSize: "10px",
            lineHeight: "12px",
          },
        },
        "&.MuiTypography-auth1": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "50px",
            lineHeight: "55px",
          },
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "30px",
            lineHeight: "35px",
          },
        },
        "&.MuiTypography-auth2": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "25px",
            lineHeight: "28px",
          },
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "15px",
            lineHeight: "20px",
          },
        },
        "&.MuiTypography-auth3": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "13px",
            lineHeight: "15px",
          },
        },
      }),
    },
  };
}
