import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";
import { createUrl } from "../utils";

export const qrCodeApi = createApi({
  baseQuery: fetchQuery(),
  reducerPath: "qrCodeApi",
  endpoints: (build) => ({
    getQrCodeAuth: build.query<
      Application.ResponseProps<QrCode.GetQrCodeAuthResponse>,
      QrCode.GetQrCodeAuthRequest
    >({
      query: (data) => createUrl(ApiRoutes.GetQrCodeAuth, { query: data }),
      transformResponse(
        baseQueryReturnValue: Application.ResponseProps<QrCode.GetQrCodeAuthResponse>,
      ): Application.ResponseProps<QrCode.GetQrCodeAuthResponse> {
        const qrValue = baseQueryReturnValue.data?.qrData ?? "";
        const indexBracketsOne = qrValue.indexOf("[");
        const indexBracketsTwo = qrValue.indexOf("]");
        if (!~indexBracketsOne || !~indexBracketsTwo) {
          return baseQueryReturnValue;
        }
        const hashValue = qrValue.slice(indexBracketsOne + 1, indexBracketsTwo);
        return {
          ...baseQueryReturnValue,
          data: {
            expiredTime: baseQueryReturnValue.data?.expiredTime ?? 60,
            qrCode: baseQueryReturnValue.data?.qrCode ?? "",
            qrData: hashValue,
          },
        };
      },
    }),
    checkQrCodeAuth: build.query<
      Application.ResponseProps<QrCode.CheckQrCodeAuthResponse>,
      QrCode.CheckQrCodeAuthRequest
    >({
      query: (data) => {
        return {
          url: createUrl(ApiRoutes.CheckQrCodeAuth, {
            query: { qrCodeContent: data.qrCodeContent },
          }),
        };
      },
    }),
  }),
});

export const {
  useCheckQrCodeAuthQuery,
  useLazyGetQrCodeAuthQuery,
  useLazyCheckQrCodeAuthQuery,
  useGetQrCodeAuthQuery,
} = qrCodeApi;
