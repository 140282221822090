import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils/UrlUtils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";

export const manageFundsApi = createApi({
  reducerPath: "manageFundsApi",
  baseQuery: fetchQuery(),
  keepUnusedDataFor: 30,
  tagTypes: ["ManageFunds", "RegularOperationsList"],
  endpoints: (builder) => ({
    /* Request data */
    getReserveList: builder.query<Application.ResponseProps<Strategy.StrategyShortProps[]>, void>({
      query: (query) => createUrl(ApiRoutes.ManageFundsGetReserveList, { query }),
    }),
    getRecurrentOperationsList: builder.query<
      Application.ResponseProps<RecurrentOperations.Props>,
      void
    >({
      query: () => ApiRoutes.ManageFundsRecurrentOperationsList,
      providesTags: ["RegularOperationsList"],
    }),

    transferStrategyToStrategy: builder.mutation<
      Application.ResponseProps<ManageFunds.TransferReserveToStrategyResponseProps>,
      ManageFunds.TransferStrategyToStrategyBodyProps
    >({
      query: (query) => ({
        url: createUrl(ApiRoutes.ManageFundsWithdrawalStrategyToStrategy, { query }),
        method: "POST",
      }),
    }),

    /* Signing */
    generateOrderCode: builder.mutation<void, ManageFunds.GenerateOrderCodeBodyProps>({
      query: (query) => createUrl(ApiRoutes.ManageFundsOrderGenerateCode, { query }),
    }),
    orderSign: builder.mutation<void, ManageFunds.OrderSignBodyProps>({
      query: (query) => createUrl(ApiRoutes.ManageFundsOrderSign, { query }),
    }),
    orderSignStatus: builder.query<
      Application.ResponseProps<ManageFunds.SignStatusProps>,
      ManageFunds.OrderSignStatusBodyProps
    >({
      query: (query) => createUrl(ApiRoutes.ManageFundsOrderSignCheck, { query }),
    }),

    /* Payments */
    payWithRequisite: builder.mutation<
      Application.ResponseProps<ManageFunds.PayWithRequisiteResponse>,
      ManageFunds.PayWithRequisiteInput
    >({
      query: (query) => createUrl(ApiRoutes.ManageFundsPaymentRequisite, { query }),
    }),
    payWithSBP: builder.mutation<
      Application.ResponseProps<ManageFunds.SbpProps>,
      ManageFunds.PayWithSPBInput
    >({
      query: (query) => createUrl(ApiRoutes.ManageFundsPaymentSBP, { query }),
    }),

    /* Withdrawal */

    withdrawalStrategyToRequisite: builder.mutation<
      Application.ResponseProps<ManageFunds.TransferGenerateResponseProps>,
      ManageFunds.WithdrawalStrategyToRequisitesBodyInput
    >({
      query: (query) => createUrl(ApiRoutes.ManageFundsWithdrawalStrategyToRequisite, { query }),
    }),

    /* Recurrent operations */
    disableRecurrentOperation: builder.mutation<Application.ResponseProps<void>, number>({
      query: (operationId) =>
        createUrl(ApiRoutes.ManageFundsRecurrentOperationDisable, { query: { operationId } }),
      invalidatesTags: ["RegularOperationsList"],
    }),
    checkCodeFromSms: builder.mutation<void, Omit<ManageFunds.OrderSignBodyProps, "faCode">>({
      query: (data) => createUrl(ApiRoutes.CheckCodeBySms, { query: data }),
    }),
  }),
});

export const {
  /* Request data */
  useGetReserveListQuery,
  useLazyGetReserveListQuery,
  useGetRecurrentOperationsListQuery,

  /* Signing */
  useOrderSignMutation,
  useOrderSignStatusQuery,
  useGenerateOrderCodeMutation,

  /* Payments */
  usePayWithRequisiteMutation,
  usePayWithSBPMutation,

  /* Withdrawals */
  useWithdrawalStrategyToRequisiteMutation,
  useCheckCodeFromSmsMutation,
  useTransferStrategyToStrategyMutation,

  /* Recurrent operations */
  useDisableRecurrentOperationMutation,
} = manageFundsApi;
