import { formatCurrencyNumber } from "../../utils";
import { Select } from "../ui/select/Select";
import { OptionValue, SelectOption } from "../ui/select/SelectOption";
import { ManageFundsInputPlaceholder } from "./ManageFundsInputPlaceholder";
import { ManageFundsStrategyLabel } from "./ManageFundsStrategyLabel";
import { useMemo } from "react";
import { makeStyles } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { PlaceholderBoxProps } from "../placeholder/PlaceholderBox";

interface Props extends Omit<PlaceholderBoxProps, "onSelect" | "onClick"> {
  readonly isLoading?: boolean;
  readonly disabled?: boolean;
  readonly onSelect: (value: Strategy.StrategyShortProps) => void;
  readonly selectedStrategy?: Strategy.StrategyShortProps | null;
  readonly strategies: Strategy.StrategyShortProps[];
  excludeStrategyId?: Strategy.StrategyShortProps["id"];
  isRemoveReserve?: boolean;
}

const useStyles = makeStyles((theme) => ({
  options: {
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginTop: "15px !important",
    },
  },
}));

export function ManageFundsSelectStrategy({
  isLoading,
  disabled,
  onSelect,
  isRemoveReserve,
  strategies: strategiesDefault,
  selectedStrategy,
  excludeStrategyId,
  ...props
}: Props) {
  const strategies = useMemo(() => {
    if (!excludeStrategyId) return strategiesDefault;
    return strategiesDefault.filter((strategy) => strategy.id !== excludeStrategyId);
  }, [strategiesDefault, excludeStrategyId]);
  const classes = useStyles();
  const selectStrategyHandler = (value: OptionValue) => {
    const selectStrategyHandler = strategies[value] as Strategy.StrategyShortProps;
    onSelect(selectStrategyHandler);
  };
  return (
    <>
      {isLoading ? (
        <ManageFundsInputPlaceholder {...props} />
      ) : (
        <Select
          {...props}
          disabled={disabled}
          placeholder="Выберите стратегию"
          onSelect={selectStrategyHandler}
          label={
            selectedStrategy && (
              <ManageFundsStrategyLabel
                title={selectedStrategy.title}
                proportion={`${selectedStrategy.proportion}%`}
                amount={`${formatCurrencyNumber(selectedStrategy.value.total)} ${
                  selectedStrategy.currency.icon
                }`}
              />
            )
          }
        >
          {strategies.map((strategy, index) => {
            return (
              <SelectOption
                classNamesProps={classes.options}
                key={`strategy-option-${index}`}
                value={index}
                title={strategy.title}
              />
            );
          })}
        </Select>
      )}
    </>
  );
}
