import { ChevronIcon } from "../icons/ChevronIcon";
import Typography from "@mui/material/Typography";
import { RoundBackButton } from "../buttons/RoundBackButton";
import React from "react";
import { makeStyles, useTheme } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  wrapperBack: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    cursor: "pointer",
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      display: "none",
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginBottom: 30,
      marginTop: 30,
    },
    [theme.breakpoints.only(Breakpoints.Laptop)]: {
      marginBottom: 20,
    },
  },
  wrapperBackMobile: {
    display: "none",
    marginTop: 14,
    marginBottom: 30,
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      display: "block",
    },
  },
  root: {
    display: "flex",
  },
}));

interface Props {
  onBack: () => void;
  wrapperBackClass?: string;
}

export const BackElTop = ({ onBack, wrapperBackClass }: Props) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div onClick={onBack} className={classNames(styles.wrapperBack, wrapperBackClass)}>
        <ChevronIcon color={theme.palette.gary.main} />
        <Typography color={theme.palette.gary.main} variant="card-secondary">
          Назад
        </Typography>
      </div>
      <div onClick={onBack} className={styles.wrapperBackMobile}>
        <RoundBackButton />
      </div>
    </div>
  );
};
