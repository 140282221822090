import { DOMParser } from "xmldom";
import * as he from "he";
export const decodeHtml = (html: string) => he.decode("<span/>" + html);
export function replaceRelativeLinks(html: string, baseUrl: string): string {
  if (!/^https?:\/\//i.test(baseUrl)) {
    baseUrl = "https://" + baseUrl.replace(/^\/\//, "");
  }
  const decodedHtml = decodeHtml(html);

  const parser = new DOMParser();
  const doc = parser.parseFromString(decodedHtml, "text/html");

  const updateLinks = (tagName: string, attribute: string) => {
    const elements = doc.getElementsByTagName(tagName);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const link = element.getAttribute(attribute);
      if (link && !link.startsWith("http") && !link.startsWith("www")) {
        let absoluteUrl = new URL(link, baseUrl).href;
        absoluteUrl = absoluteUrl.replace("www.", ""); // Убираем www
        absoluteUrl = absoluteUrl.replace(/^http:/, "https:"); // Принудительно HTTPS
        element.setAttribute(attribute, absoluteUrl);
      }
    }
  };

  updateLinks("a", "href");
  updateLinks("img", "src");

  return doc.toString();
}
