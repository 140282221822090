import React from "react";
import cx from "classnames";
import Typography from "@mui/material/Typography";

import { makeStyles, useIsTabletUp } from "../../theme/Theme";
import { formatHexToRGBA } from "../../utils/FormatUtils";
import { PlaceholderBox } from "../placeholder/PlaceholderBox";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { Box, BoxProps, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  marker: {
    marginRight: theme.typography.pxToRem(8),
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      alignItems: "unset",
      flexDirection: "column",
      marginBottom: theme.typography.pxToRem(28),
    },
  },
  chart: {
    marginBottom: theme.typography.pxToRem(10),
  },
  legend: {
    "&.MuiTypography-root": {
      marginRight: theme.typography.pxToRem(12),
      color: formatHexToRGBA({ color: theme.palette.gary.main, alpha: 0.2 }),
    },
  },
}));

interface Props extends BoxProps {
  strategyTitle?: string;
}

export function ManageFundsStrategyChartPlaceholder({ className, strategyTitle, ...props }: Props) {
  const classes = useStyles();

  const isSmallUp = useIsTabletUp();

  return (
    <Box {...props} className={cx(classes.root, className)}>
      <PlaceholderBox height={12} className={classes.chart} />

      <Stack spacing={1} direction={!isSmallUp ? "column" : "row"}>
        <Stack direction="row" alignItems="center">
          <PlaceholderBox width={16} height={8} radius={8} className={classes.marker} />
          <Typography variant="text2" className={classes.legend}>
            {strategyTitle ?? ""}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <PlaceholderBox width={16} height={8} radius={8} className={classes.marker} />
          <Typography variant="text2" className={classes.legend}>
            Рублевый резерв
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
